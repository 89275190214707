.App {
  text-align: center;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.StrokeLarge {
  font-size: 80px;
  -webkit-text-stroke: 1px #B8B8B8;
  color: white;
  font-family: sans-serif !important;
  letter-spacing: -5px;
}

@media (max-width: 900px) {
  .StrokeLarge {
    font-size: 60px;
    letter-spacing: -2px;
  }
}

.marquee-section,
.marquee-section * {
  width: max-content;
  overflow: hidden;
}

.marquee {
  white-space: nowrap;
}

.marquee-div {
  animation: move-left-to-right linear infinite;
}

@keyframes move-left-to-right {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}