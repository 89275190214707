.menuIcon {
    font-size: 30px !important;
    color: var(--primary) !important;
}

.SocialNavIcon {
    font-size: 30px !important;
    margin-left: 10px;
    cursor: pointer;
}

.SocialNavIcon:hover {
    color: var(--primary);
}

.NavLinks {
    font-size: 16px !important;
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    font-weight: 500 !important;
}

.InsideLink {
    margin-left: 30px !important;
}

.NavLinks:hover {
    cursor: pointer;
    color: var(--primary) !important;
}


.drawer .MuiPaper-root {
    width: 100%;
    max-width: 70vw;
    min-width: 200px;
}


@media only screen and (max-width: 1024px) {

    .NavLinks {
        padding: 10px 40px;
    }
}