.grText {
    background: linear-gradient(to right, #03624c, #942D2D);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
}

.grText1 {
    background: linear-gradient(to right, #03624ccc, #942D2Dcc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
}