.CardDesc {
    color: white;
    display: none;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
}

.ServCard:hover .CardDesc {
    display: block !important;
}