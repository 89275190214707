:root {
  /* -----colors----- */
  --primary: #03624c;
  --secondary: #f1f7f7;
  --text-primary: #000000;
  --text-secondary: #ffffff;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Radio Canada Big", sans-serif !important;
  text-decoration: none;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  transition: 0.2s;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}