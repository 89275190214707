.SocialFooterIcon {
    font-size: 35px !important;
    cursor: pointer;
    color: white;
}

.footerLinks {
    color: #B8B8B8;
    font-size: 16px;
    margin-left: 0px;
    margin-top: 10px;
    list-style-type: none;
}

.footerLinks li {
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 15px;
}

.footerLinks li:hover {
    text-decoration: underline;
}

.footerListIcon {
    padding: 5px;
    display: flex;
    margin-top: 5px;
    font-size: 15px;
    color: white;
    max-width: 250px;
}

.footerListIcon div {
    border-radius: 50%;
    background-color: rgb(48, 48, 48);
    height: 30px;
    width: 30px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.footerListIcon svg {
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    .SocialFooterIcon {
        margin: 0 10px;
    }

    .footerListIcon {
        display: none;
    }

}